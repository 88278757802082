.container {
    min-height: 100%;
}

.navbar {
    padding: 15px 0;

    :global(.navbar-header) {
        position: relative;
    }

    .logo {
        transition: opacity 0.5s ease;
        opacity: 0;
        max-height: 30px;
        left: 0;
        top: 0;
        position: absolute;
    }
}


.img {
    max-width: 100%;
}

.banner {
    position: relative;

    .text {
        position: absolute;
        left: 50%;
        width: 50%;
        text-align: center;
        top: 48%;
        color: #260000;
        font-weight: lighter;
        line-height: 2;
    }
}

.navbar_wrap {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    left: 0;
    background: #fff;
    z-index: 11;
}

.video-wrap {
    video {
        width: 100%;
    }

    .play-btn {
        background: url('./images/WechatIMG438.png');
        background-size: cover;
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;

        img {
            &:active {
                opacity: 0.8;
            }
        }
    }
}

.container1 {
    background-image: linear-gradient(0deg, #414141 0%, #1A1A1A 100%);
    color: #fff;
    line-height: 2;

    h4 {
        font-size: 28px;

    }

    h6 {

        font-size: 18px;
        line-height: 1.5;
    }

    p {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: lighter;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.arrow {
    position: absolute;
    bottom: 15px;
    left: 0%;
    width: 100%;
    text-align: center;
    animation: animation1 1s ease infinite;

    img {
        width: 20px;
        position: relative;

    }
}

@keyframes animation1 {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: translateY(-50%);
        opacity: 0;
    }
}

.container2 {
    background-image: linear-gradient(0deg, #414141 0%, #1A1A1A 100%);

    color: #fff;
    line-height: 2;

    img {
        width: 100%;
    }
}


@media (min-width: 768px) {
    .navbar {
        padding: 20px 1rem;

        .logo {
            max-height: 40px;
        }
    }

    .slide3_1 {
        position: absolute;
        bottom: 0;
        line-height: 2;
        font-size: 18px;
        left: 35%;
    }

    .text1 {
        font-size: 18px;
    }
}

.text {
    line-height: 1.5;
    font-size: 16px;
}

.text1 {
    margin-top: 0.5em;
    font-size: 15px;
    color: #666;
}

.img-wrap {
    background: #495259;
    padding: 10px;
    display: inline-block;
    overflow: hidden;
}