.canvas{
  width: 100%;
}
html,body{
  height: 100%;
 }
#root,html,body{
 height: 100%;
}
.text-tap-tips{
  font-size: 12px;
  color: #999;
}
.wrap{
  position: relative;
}
.wrap  img{
  position: relative;
  z-index: 3;
}
.wrap  .bg{
  position: absolute;
  z-index: 10;
  width: 100%;
  left: 0;
}
.wrap video{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.z-index-2{
  z-index: 2;
}
.z-index-3{
  z-index: 3;
}
.fadeOut-exit-active {
  z-index: 999;
  overflow: hidden;
  animation: fadeOut 1s ease;
  animation-fill-mode: both;
}
@keyframes fadeOut{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.mt-15px{
  margin-top: 15px;
}

@media screen and (min-width: 576px) { 
  .mt-sm-30{
      margin-top: 30px;
  }
}
footer{
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  color: #666!important;
  padding: 30px 15px;
  font-size: 14px;
  a{
    color: #666!important;
    
    &:hover{
      opacity: 0.5;
    }
  }
}

.h-100vh{
  height: 100vh; 
}
.transition-duration-500ms{
  transition-duration: 500ms;
}
.transition-delay-500ms{
  transition-delay: 500ms;
}
.fadeInUp{
  
  &.delay-500ms{
    animation-delay: 500ms;
  }
}
.swiper-slide-active{
  .fadeInUp{
    animation: fadeInUp 0.5s ease;
  animation-fill-mode: both;
   
  }
}


.swiper-scroll-wrap{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 111111;
  width:100%;
  overflow: auto;
  >div{
    content: " ";
  }
}
.swiper-slide{
  position: absolute!important;
  left: 0;
  top:0;
  
  >.container>div{
    padding-top: 72px;
    display: flex;
    align-items: center;
    min-height: 100vh;
    background: #fff;
  }
}
.swiper-slide-active{
  z-index: 2;
}
.swiper-slide-next{
  z-index: 3;
}
.swiper-slide-prev{
  z-index: 1;
}
@keyframes fadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes fadeInUp{
  0%{
    opacity: 0;
    transform: translateY(-100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeOutUp{
 
  0%{
    opacity: 1;
    transform: translateY(0%);
  }
  100%{
    opacity: 0;
    transform: translateY(-100%);
  }
}

.swiper-scrollbar{
  position: fixed!important;
 
  z-index: 99999!important;
}